/*----------------------theme------------------------*/
body{    
    font-family: $mfont; 
    background-color:$bg1;   
    overflow-x: hidden;
    opacity: 0;
    scroll-behavior: smooth;
    // visibility: hidden;
    &.fade-in{
        opacity: 1;
        transition:opacity  2s ease ;
        // visibility: visible;
    } 
}
main{
}
.row{
    // .col-8{
    //     flex:0 0 66.7%;
    //     width: 66.7%;
    // }  
    @include mediaquery(null,$md){
    }
    @include mediaquery($md,null){
        display: flex;
        flex-wrap: wrap;
        .col{
            flex:0 0 100%;
            width:100%;
        }
        .col-6{
            flex:0 0 50%;
            width:50%;
        }
    }
}
@include mediaquery(null,$md){
    .row:not(.nopd),.editorBox{
        padding:0 $pdr-mo;
    }   
    .footerH{
        width: 100%;
        height: 100px;
    }    
    .formH{
        width: 100%;
        height: 120px;
    }
    #Wapper{
        position: relative;
        padding-top: $headH-mo;
    }
}
@include mediaquery($md,null){
    .row:not(.nopd),.editorBox{
        padding:0 $pdr-pc; 
    }  
    .row.vdobx{
        .col-6{
            padding-bottom: 8px;
        }
        .col-6:nth-child(odd){
            padding-right: 4px;
        }
        .col-6:nth-child(even){
            padding-left: 4px;
        }
    }
    .footerH{
        width: 100%;
        height: 230px;
    }    
    .formH{
        width: 100%;
        height: 120px;
    }
    #Wapper{
        position: relative;
        padding-top: $headH-pc;
    }
}
//中英標題
h1.mtitle,h6.mtitle{    
    .ftw{
        @include font-color($color2,400,1.2);
        @include font-size(pxToEm(25),pxToEm(34));
    }
    .fen{
        @include font-color($color2,400,1.2);
        @include font-size(pxToEm(25),pxToEm(36));
    }
    &.fwh .ftw,
    &.fwh .fen{
        color:white;
    }
    @include mediaquery(null,$md){
        @include position(absolute,$top:1.3em,$left:0);
    }
    @include mediaquery($md,null){
        @include position(absolute,$top:26px,$left:0);
    }
}
//表單引導文字
.form-txt1{
    h1{
        @include font-color($color2,400,1.2);
        font-size: pxToEm(18);
        margin-bottom: 6px;
        font-family: $fen-bold;
    }
    .stext{
        @include font-color($color2,400,1.75);
        font-size: pxToEm(16);
    }
    @include mediaquery(null,$md){     
        margin: 12px 0;
    }
    @include mediaquery($md,null){        
        margin: 24px 0;
    }
}
//表單內頁的標題
.ftitle{
    text-align: center;
    @include font-color($color2,700,1);
    font-size: pxToEm(18);
    @include mediaquery(null,$md){
        margin:30px 0 24px;
    }
    @include mediaquery($md,null){
        margin:48px 0 24px;
    }
}
.row-col-8{
    max-width: 1305px;
    width:100%;
    margin: 0 auto;
    @include mediaquery(null,$md){
        padding:0 $pdr-mo;
    }
    @include mediaquery($md,null){
        padding:0 $pdr-pc;
    }
}
.row-col-4{
    max-width: 690px;
    width:100%;
    margin: 0 auto;
    @include mediaquery(null,$md){
        padding:0 $pdr-mo;
    }
    @include mediaquery($md,null){
        padding:0 $pdr-pc;
    }
}
.temp-bg{
    position: relative;
    .bgcolor{
        background-color: rgba($color1,0.8);
        @include position(absolute,$top:0,$left:0,$right:0,$bottom:0);
        padding:54px 45px;
        opacity: 0;
        transition: opacity 0.6s;
        @include mediaquery(null,$md){
            padding:18px $pdr-mo;
        }
        @include mediaquery($md,null){
            padding:54px $pdr-pc;
        }
    }
    .ftw{
        @include font-color(white,400,1.2);
        @include font-size(pxToEm(25),pxToEm(34));
    }
    .fen{
        @include font-color(white,400,1.2);
        @include font-size(pxToEm(25),pxToEm(36));
    }
    a{
        display: none;
        @include position(absolute,$top:0,$left:0,$right:0,$bottom:0);
    } 
    &.hover{
        .bgcolor{
            opacity: 1;
        }
    }
    @include mediaquery($md,null){        
        &.hover{
            a{
                display: block;
            }
        }
    }
}
.slick-img{
    @include mediaquery(null,$md){
        .temp-bg{
            width:84.9vw;
        }
    }
    @include mediaquery($md,null){
        .temp-bg{
            width:40.83vw;
        }
    }
}
.search{
    margin-top: -0.5em;
    a{
        @include font-color($color3,400,2.5);
        @include font-size(pxToEm(16),pxToEm(14));
        @include clamp(2.5,1);
    }
    i{
        color:$color2;
    }
    a:hover{
        color:$color1;
    }
}
.no-search{
    text-align: center;
    @include font-color($color2,400,1.57);
    @include font-size(pxToEm(16),pxToEm(14));
}
//購物紀錄
.history{
    @include font-color($color2,400,1.7);
    @include font-size(pxToEm(16),pxToEm(14));
    li:nth-child(odd){
        background-color: #d7d7d7;
    }
    @include mediaquery(null,$md){
        li{
            padding:8px 0;
        }
    }
    @include mediaquery($md,null){
        li{
            padding:15px 0;
        }
    }
}
//藝術家
.ambs{
    .mCSB_scrollTools .mCSB_draggerContainer{
        opacity: 0;
    }
    .mCSB_horizontal.mCSB_inside>.mCSB_container{
        margin-bottom: 0;
    }
    .scrol-img{
        display: flex;
    }
    .temp-bg{
        @include mediaquery(null,$md){
            width:84.9vw;
            flex:0 0 84.9vw;
        }
        @include mediaquery($md,null){            
            width:45.57vw;
            flex:0 0 45.57vw;
        }
    }
    .fen{
        display: block;
    }
}
//影片分類
.v_filter{    
    width: 100%;
    @include font-color($color3,400,1.5);
    @include font-size(pxToEm(16),pxToEm(14));    
    display: flex;
    .txt{
        flex:0 0 5.5em;
    }
    a{
        color: $color3;
        margin-right: 1em;
        white-space: nowrap;
    }
    a:before{
        content:"●";
        margin-right: 0.5em;  
        color:#d7d7d7;
    }
    a.active:before{        
        color:$color1;
    }
    @include mediaquery(null,$md){
        padding:20px $pdr-mo;
        overflow-x: scroll;
        .cate{
            display: flex;
            
        }
    }
    @include mediaquery($md,null){
        padding:28px $pdr-pc;
    }
}
//搜尋後的錨點
.srline{
    width: 100%;
    height: 1px;
    @include mediaquery(null,$md){
        transform: translateY(-55px);
    }
    @include mediaquery($md,null){
        transform: translateY(-67px);
    }
}