.indx-banner{
    .bgcover{
        padding-bottom: 70%;
    }
    .btn-slick-prev,.btn-slick-next{
        transform: translateY(-50%);
        cursor: pointer;
    }
    .btn-slick-prev{
        @include position(absolute,$left:0,$top:50%);
    }
    .btn-slick-next{
        @include position(absolute,$right:0,$top:50%);
    }
    @include mediaquery(null,$md){
        .btn-slick-prev,.btn-slick-next{
            width: 24px;
        }
    }
}
.indx-about{  
    @include font-color($color1,400,1.15);
    @include font-size(pxToEm(25),pxToEm(36));
    a{
        position: relative;
        color: $color1;
    }
    a:after{
        content:"";
        width: 100%;
        height: 2px;
        background-color: $color1;
        @include position(absolute,$bottom:0,$left:0px);
    }
    .col{
        max-width: 20em;
    }
    @include mediaquery(null,$md){
        padding:20px $pdr-mo;
    }
    @include mediaquery($md,null){
        padding:45px $pdr-pc;
    }
}
.indx-pdf{
    background-color: $color1;
    @include font-color(white,400,1.15);
    .mtitle{
        @include font-size(pxToEm(25),pxToEm(36));
    }
    a{
        @include font-size(pxToEm(14),pxToEm(18)); 
        color:white; 
    }
    @include mediaquery(null,$md){
        &.row:not(.nopd){
            padding-top: 16px;
            padding-bottom: 56px;
        }
        .mtitle{
            margin-bottom: 16px;
        }
        a.txt{
            @include position(absolute,$bottom:24px,$left:$pdr-mo);
        }
    }
    @include mediaquery($md,null){
        .col-6{
            padding:45px 0;
        }
        .mtitle{
            margin-bottom: 32px;
        }
    }
}