header{
    // width:$maxConentW;   
    // box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.35);
    .title-bar{   
        width: 100%;
        background-color: $bg1;
        z-index: 10;
        position: relative;
        @include position(fixed,$top:0,$left:50%);
        transform: translateX(-50%);
        z-index: 101;     
        @include mediaquery(null,$md){
            box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25);
        }
        @include mediaquery($md,null){
            box-shadow: 0px 1px 13.3px 0.7px rgba(34, 24, 21, 0.25);
        }
    }
    //menu影藏和展開的位置
    .navBox{       
        @include position(fixed,$left:0%);      
        z-index: 99;        
        // max-width: $maxConentW;      
        // height:100%;
        width: 100%;   
        background-color: rgba($bg1,0.9);
        box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25);
        // -webkit-overflow-scrolling: touch;
        // overflow-y: scroll;
        display: none;
        @include mediaquery(null,$md){
            padding:16px 0 12px; 
        }
        @include mediaquery($md,null){
            padding:30px 0; 
        }
    }    
    .logo{
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        z-index: 10;
        @include mediaquery(null,$md){
            left:$pdr-mo;
            width: 120px;
        }
        @include mediaquery($md,null){
            left:$pdr-pc;
            width: 186px;
        }
    }  
    .menuicon{
        @include position(absolute, $top:50%,$left:50%);
        transform: translate(-50%,-50%);
        z-index: 10;  
        @include mediaquery(null,$md){ 
            width: 24px;
        }
        @include mediaquery($md,null){ 
            width: 36px;
        }
    }
    // .menuicon{
    //     @include position(absolute, $top:50%,$right:$pdr-m);
    //     transform: translateY(-50%);
	// 	z-index: 10;       
    //     .mpsrel{
    //         width:40px;
    //         height:40px;
    //     }
    //     .bar,.bar:after,.bar:before{
    //         transition: all .25s;
    //         content: "";
    //         @include position(absolute,$right:0%,$top:0);
    //         height: 1.5px;   
    //         width: 32px;   
    //         background:rgba(#fffded,1);       
    //     }
    //     .bar{
    //         margin-top: 19px;
    //     }
    //     .bar:before{top: -12px;}
    //     .bar:after{top: 12px;}
    // }
    // //mobile meun open
    // &.menu-expanded{
    //     .menuicon .bar{
    //         background:rgba(black,0);
    //         &:before{
    //             top:0px;
    //             transform:rotate(45deg);
    //             width: 36px;
    //             background:rgba(#fffded,1);  
    //         }
    //         &:after{
    //             top:0px;
    //             transform: rotate(-45deg);
    //             width: 36px;
    //             background:rgba(#fffded,1);  
    //         }
    //     }
    // }  
    //mmenu
    ul.mmenu{  
		> li{
            text-align: center;
            > a{
                @include font-color($color3,400,1.67);
            }
            .subBox a{
                @include font-color($color1,400,1.67);
            }
            a{
                @include font-size(pxToEm(16),pxToEm(18));
                @include mediaquery(null,$sm){
                    line-height: 2;
                }
            }
            .subBox{
                display: none;
            }
            @include mediaquery(null,$md){
                a{
                    display: block;
                    margin-bottom: 6px;
                }
            }
        }  
        
    }//end mmenu  
    //高度
    @include mediaquery(null,$md){
        .title-bar{            
            height: $headH-mo;
        }
        .ul.mmenu{
            padding-top: $headH-mo;
        }
        .otherBox,.navBox{
            top:$headH-mo;
        }
    }
    @include mediaquery($md,null){ 
        .title-bar{
            height: $headH-pc;
        }
        .ul.mmenu{
            padding-top: $headH-pc;
        }
        .otherBox,.navBox{
            top:$headH-pc;
        }
    }
    .iconbx{
        display: flex;
        align-items: center;
        @include position(absolute,$top:50%);
        transform: translateY(-50%);
        path{
            fill:#d7d7d7;
        }
        .mfil path{
            fill:$color1;
        }
        @include mediaquery(null,$md){
            right: $pdr-mo;            
            .srhbtn{
                .svg{
                    width: 23px;
                    height: 20px;
                }
                padding:5px;
                margin-right: 10px;
                margin-top: 5px;
            }    
            .logbtn{
                .svg{
                    width: 18px;
                    height: 20px;
                }
                padding:5px;
                margin-top: 5px;
            }
        }
        @include mediaquery($md,null){
            right: $pdr-pc;            
            .srhbtn{
                .svg{
                    width: 35px;
                    height: 35px;
                }
                .svg:hover path{
                    fill:$color1;
                }
                margin-right: 26px;
            }    
            .logbtn{
                .svg{
                    width: 26px;
                    height: 31px;
                }
            }
        }
    }
    .otherBox{
        @include position(fixed,$left:0%);      
        z-index: 99;        
        width: 100%;   
        background-color: rgba($bg1,0.9);
        box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25);
        @include mediaquery(null,$md){
            padding:12px $pdr-mo;
        }
        @include mediaquery($md,null){
            padding:15px $pdr-pc;
        }
    }    
    #nav-account,#nav-search{
        display: none;
    }
    #nav-account{
        text-align: right;
        @include font-size(pxToEm(16),pxToEm(18));
        .fcolr1{
            color:$color1;
        }
        .fcolr3,a{
            color: $color3;
        }
        @include mediaquery(null,$md){
            padding:11px $pdr-mo 10px;
        }
        @include mediaquery($md,null){
            padding:26px $pdr-pc;
            div{
                display: inline-block;
            }
        }
    }
    .searchbx{
        position: relative;
        @include border();
        background-color: white;
        overflow: hidden;
        span{
            text-align: center;
            height: 100%;
            @include position(absolute,$right:0,$top:0);
            background-color: $color1;
            color: white;
            cursor: pointer;
        }
        input{
            color:black;
        }
        span,input{
            @include font-size(pxToEm(16),pxToEm(18));
        }
        @include mediaquery(null,$md){
            width: 100%;
            input{
                padding-right: 95px;
                padding:9px 24px 7px;
            }
            span{
                width: 95px;
                line-height: 40px;
            }
        }
        @include mediaquery($md,null){
            float: right;
            padding: 6px 0;
            width: 386px;
            input{
                padding-right: 130px;
            }
            span{
                width: 120px;
                line-height: 48px;
            }
        }
    }
    // .formtable{
    //     background-color: #f7f8f8;
    //     transition: top 0.6s cubic-bezier(0.260, 0.005, 0.000, 1.035);
    //     @include position(fixed,$top:-30px,$left:50%);
    //     transform: translateX(-50%);
    //     z-index: 99;
    //     padding:24px $pdr-m;
    //     max-width: $maxConentW;
    //     width: 100%;
    //     &.is-open{
    //         top:$headH-mobile;
    //     }
    //     .searchbx{
    //         display: flex;
    //     }
    //     input,span{
    //         @include border();
    //     }
    //     input{
    //         background-color: white;
    //         @include font-color($color2,400,1);
    //         font-size: 16px;
    //         box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
    //         flex:1;
    //     }
    //     span{
    //         @include font-color(white,400,1);
    //         background-color: $color2;
    //         text-align: center;
    //         cursor: pointer;
    //         padding:16px 5px 11px;
    //         font-size: 14px;
    //         flex:0 0 90px;
    //         margin-left: 12px;
    //     }
    // }
}
