footer{
    position: relative;
    background-color:$bg1;
    @include font-color($color2,400,1.5);
    @include font-size(pxToEm(12),pxToEm(16));
    > a{
        margin-right: 5px;
    }
    .copyright a{
        color:$color3;
        display: inline-block;
    }
    .copyright i{
        display: inline-block;
        margin-right: 0.5em;
    }
    &.nopd{        
        @include mediaquery(null,$md){
            // padding-bottom: 40px;
            padding:12px $pdr-mo 40px; 
        }
        @include mediaquery($md,null){
            // padding-bottom: 12px;
            padding:12px $pdr-pc 12px; 
        }
    }
}
.f-info{
    // font-family: $fen;
    @include font-color($color2,400,1.5);
    @include font-size(pxToEm(14),pxToEm(18));
    a,a:hover{
        color:$color2;
    }
    b{
        font-weight: 700;
        // font-family: $mfont;
    }
    strong{
        font-family: $fen-bold;
    }
    ul{
        max-width: 23em;
    }
    ul:not(:last-child){
        margin-bottom: 20px;
    }
    &.row.indx{
        @include mediaquery(null,$md){
            padding:16px $pdr-mo 24px;
            span{
                width: 105px;
                display: inline-block;
                margin-right: 18px;
                margin-top: 18px;
            }
        }
        @include mediaquery($md,null){
            padding:28px $pdr-pc 48px;
            span{
                display: inline-block;
                margin-right: 24px;
            }
        }
    }
    &.contact{
        @include mediaquery(null,$md){
            margin:36px 0 20px;
        }
        @include mediaquery($md,null){
            margin:46px 0 120px;
        }
    }
}