.news{
    .items:nth-child(even){
        background-color: #d7d7d7;
    }
    .stext{
        h5{
            font-weight: bold;
        }
        @include font-color($color2,400,1.75);
        @include font-size(pxToEm(16),pxToEm(16));
        @include mediaquery(null,$md){
            line-height: 1.56;
            padding:14px 0 20px;
        }
        @include mediaquery($md,null){
            padding:24px 0 30px;
        }
    }
    .mimg{      
        @include mediaquery(null,$md){
            padding-top:20px;
        }
        @include mediaquery($md,null){
            padding-top:30px;
        }
    }
    .videobx{
        background-color: black;  
        @include mediaquery(null,$md){
            padding:$pdr-mo 0;
        }
        @include mediaquery($md,null){
            padding:30px 0;
        }
    }
    footer{
        background-color: rgba(0,0,0,0);
    }
}