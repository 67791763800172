@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500&display=swap');

@font-face {
    font-family: 'Montserrat-Regular';
    src: local("Montserrat-Regular"),
        url('../assets/Montserrat-Regular.ttf') format("opentype");
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: local("Montserrat-Bold"),
        url('../assets/Montserrat-Bold.ttf') format("opentype");
}
$mfont: 'Montserrat-Regular','Noto Sans SC',"Microsoft Yahei","Microsoft JhengHei", sans-serif;
$mfont-bold:'Montserrat-Bold','Noto Sans SC',"Microsoft Yahei","Microsoft JhengHei", sans-serif;
$fen:'Montserrat-Regular', sans-serif;
$fen-bold:'Montserrat-Bold', sans-serif;

$color1:#987033;
$color2:#221815;
$color3:#8c8c8c;
$bg1:#f0f0f0;

$headH-pc:67px;
$headH-mo:55px;


$pdr-mo:22px;
$pdr-pc:60px;//$body-pdr-pc


.fen{
    font-family: $fen;
}
.bgwhite{
    background-color: white;
}
.bg1{
    background-color: $bg1;
}
