@charset "UTF-8";
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto; }

.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp {
  background-image: none;
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0; }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px; }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px; }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px; }

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: .9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@font-face {
  font-family: 'Montserrat-Regular';
  src: local("Montserrat-Regular"), url("../assets/Montserrat-Regular.ttf") format("opentype"); }

@font-face {
  font-family: 'Montserrat-Bold';
  src: local("Montserrat-Bold"), url("../assets/Montserrat-Bold.ttf") format("opentype"); }

.fen {
  font-family: "Montserrat-Regular", sans-serif; }

.bgwhite {
  background-color: white; }

.bg1 {
  background-color: #f0f0f0; }

/*----------------------通用設定------------------------*/
::selection {
  color: #fff;
  background: #987033; }

a {
  outline: none; }

a, a:hover {
  text-decoration: none;
  transition: 0.4s;
  color: initial; }

*:focus {
  outline: none; }

img {
  vertical-align: middle;
  max-width: 100%; }

/*----------------------共用樣式------------------------*/
.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.mpsrel {
  position: relative; }

.bgcover {
  width: 100%;
  height: 0;
  background: center / cover; }

/*----------------------編輯器------------------------*/
.editor_inner {
  color: #221815;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.01em;
  font-size: 1rem;
  margin: 0 auto; }
  .editor_inner ul, .editor_inner ol {
    padding-left: 10px; }
  .editor_inner ol {
    list-style: decimal; }
  .editor_inner ul {
    list-style: disc; }
  .editor_inner img {
    max-width: 100%;
    height: auto !important; }
  .editor_inner b, .editor_inner strong {
    font-weight: bold;
    font-style: inherit; }
  .editor_inner em {
    font-style: italic; }
  .editor_inner a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_inner iframe {
    max-width: 100%; }
  .editor_inner h1, .editor_inner h2, .editor_inner h3, .editor_inner h4, .editor_inner h5, .editor_inner h6 {
    margin: inherit; }
  .editor_inner blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    border-left: 5px solid #ccc;
    margin-left: 40px; }
  @media all and (max-width: 575px) {
    .editor_inner {
      line-height: 1.5625; } }
  @media all and (max-width: 768px) {
    .editor_inner.show-pc {
      display: none; } }
  @media all and (min-width: 769px) {
    .editor_inner.show-mo {
      display: none; }
    .editor_inner + .back {
      margin-top: 100px; } }

/*-------------------背景圖片 桌手機分開-----------------*/
@media all and (max-width: 575px) {
  .bgcover[data-src-lg] {
    content: attr(data-src-lg, url); } }

@media all and (min-width: 576px) {
  .bgcover[data-src-sm] {
    content: attr(data-src-sm, url); } }

#topBtn {
  width: 80px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden; }
  #topBtn.open {
    opacity: 1;
    visibility: visible; }
  @media all and (max-width: 768px) {
    #topBtn {
      width: 60px; } }
  @media all and (min-width: 769px) {
    #topBtn {
      width: 80px; } }

/*---------------------video----------------------*/
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item, .embed-responsive iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .embed-responsive.embed-responsive-16by9:before {
    content: "";
    display: block;
    padding-top: 69.58%; }

header .title-bar {
  width: 100%;
  background-color: #f0f0f0;
  z-index: 10;
  position: relative;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101; }
  @media all and (max-width: 768px) {
    header .title-bar {
      box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25); } }
  @media all and (min-width: 769px) {
    header .title-bar {
      box-shadow: 0px 1px 13.3px 0.7px rgba(34, 24, 21, 0.25); } }

header .navBox {
  position: fixed;
  left: 0%;
  z-index: 99;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.9);
  box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25);
  display: none; }
  @media all and (max-width: 768px) {
    header .navBox {
      padding: 16px 0 12px; } }
  @media all and (min-width: 769px) {
    header .navBox {
      padding: 30px 0; } }

header .logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; }
  @media all and (max-width: 768px) {
    header .logo {
      left: 22px;
      width: 120px; } }
  @media all and (min-width: 769px) {
    header .logo {
      left: 60px;
      width: 186px; } }

header .menuicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; }
  @media all and (max-width: 768px) {
    header .menuicon {
      width: 24px; } }
  @media all and (min-width: 769px) {
    header .menuicon {
      width: 36px; } }

header ul.mmenu > li {
  text-align: center; }
  header ul.mmenu > li > a {
    color: #8c8c8c;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: 0.01em; }
  header ul.mmenu > li .subBox a {
    color: #987033;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    header ul.mmenu > li a {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    header ul.mmenu > li a {
      font-size: 1.125rem; } }
  @media all and (max-width: 575px) {
    header ul.mmenu > li a {
      line-height: 2; } }
  header ul.mmenu > li .subBox {
    display: none; }
  @media all and (max-width: 768px) {
    header ul.mmenu > li a {
      display: block;
      margin-bottom: 6px; } }

@media all and (max-width: 768px) {
  header .title-bar {
    height: 55px; }
  header .ul.mmenu {
    padding-top: 55px; }
  header .otherBox, header .navBox {
    top: 55px; } }

@media all and (min-width: 769px) {
  header .title-bar {
    height: 67px; }
  header .ul.mmenu {
    padding-top: 67px; }
  header .otherBox, header .navBox {
    top: 67px; } }

header .iconbx {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  header .iconbx path {
    fill: #d7d7d7; }
  header .iconbx .mfil path {
    fill: #987033; }
  @media all and (max-width: 768px) {
    header .iconbx {
      right: 22px; }
      header .iconbx .srhbtn {
        padding: 5px;
        margin-right: 10px;
        margin-top: 5px; }
        header .iconbx .srhbtn .svg {
          width: 23px;
          height: 20px; }
      header .iconbx .logbtn {
        padding: 5px;
        margin-top: 5px; }
        header .iconbx .logbtn .svg {
          width: 18px;
          height: 20px; } }
  @media all and (min-width: 769px) {
    header .iconbx {
      right: 60px; }
      header .iconbx .srhbtn {
        margin-right: 26px; }
        header .iconbx .srhbtn .svg {
          width: 35px;
          height: 35px; }
        header .iconbx .srhbtn .svg:hover path {
          fill: #987033; }
      header .iconbx .logbtn .svg {
        width: 26px;
        height: 31px; } }

header .otherBox {
  position: fixed;
  left: 0%;
  z-index: 99;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.9);
  box-shadow: 0px 1px 6px 0.5px rgba(34, 24, 21, 0.25); }
  @media all and (max-width: 768px) {
    header .otherBox {
      padding: 12px 22px; } }
  @media all and (min-width: 769px) {
    header .otherBox {
      padding: 15px 60px; } }

header #nav-account, header #nav-search {
  display: none; }

header #nav-account {
  text-align: right; }
  @media all and (max-width: 575px) {
    header #nav-account {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    header #nav-account {
      font-size: 1.125rem; } }
  header #nav-account .fcolr1 {
    color: #987033; }
  header #nav-account .fcolr3, header #nav-account a {
    color: #8c8c8c; }
  @media all and (max-width: 768px) {
    header #nav-account {
      padding: 11px 22px 10px; } }
  @media all and (min-width: 769px) {
    header #nav-account {
      padding: 26px 60px; }
      header #nav-account div {
        display: inline-block; } }

header .searchbx {
  position: relative;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background-color: white;
  overflow: hidden; }
  header .searchbx span {
    text-align: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #987033;
    color: white;
    cursor: pointer; }
  header .searchbx input {
    color: black; }
  @media all and (max-width: 575px) {
    header .searchbx span, header .searchbx input {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    header .searchbx span, header .searchbx input {
      font-size: 1.125rem; } }
  @media all and (max-width: 768px) {
    header .searchbx {
      width: 100%; }
      header .searchbx input {
        padding-right: 95px;
        padding: 9px 24px 7px; }
      header .searchbx span {
        width: 95px;
        line-height: 40px; } }
  @media all and (min-width: 769px) {
    header .searchbx {
      float: right;
      padding: 6px 0;
      width: 386px; }
      header .searchbx input {
        padding-right: 130px; }
      header .searchbx span {
        width: 120px;
        line-height: 48px; } }

footer {
  position: relative;
  background-color: #f0f0f0;
  color: #221815;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    footer {
      font-size: 0.75rem; } }
  @media all and (min-width: 576px) {
    footer {
      font-size: 1rem; } }
  footer > a {
    margin-right: 5px; }
  footer .copyright a {
    color: #8c8c8c;
    display: inline-block; }
  footer .copyright i {
    display: inline-block;
    margin-right: 0.5em; }
  @media all and (max-width: 768px) {
    footer.nopd {
      padding: 12px 22px 40px; } }
  @media all and (min-width: 769px) {
    footer.nopd {
      padding: 12px 60px 12px; } }

.f-info {
  color: #221815;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .f-info {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .f-info {
      font-size: 1.125rem; } }
  .f-info a, .f-info a:hover {
    color: #221815; }
  .f-info b {
    font-weight: 700; }
  .f-info strong {
    font-family: "Montserrat-Bold", sans-serif; }
  .f-info ul {
    max-width: 23em; }
  .f-info ul:not(:last-child) {
    margin-bottom: 20px; }
  @media all and (max-width: 768px) {
    .f-info.row.indx {
      padding: 16px 22px 24px; }
      .f-info.row.indx span {
        width: 105px;
        display: inline-block;
        margin-right: 18px;
        margin-top: 18px; } }
  @media all and (min-width: 769px) {
    .f-info.row.indx {
      padding: 28px 60px 48px; }
      .f-info.row.indx span {
        display: inline-block;
        margin-right: 24px; } }
  @media all and (max-width: 768px) {
    .f-info.contact {
      margin: 36px 0 20px; } }
  @media all and (min-width: 769px) {
    .f-info.contact {
      margin: 46px 0 120px; } }

/*----------------------theme------------------------*/
body {
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif;
  background-color: #f0f0f0;
  overflow-x: hidden;
  opacity: 0;
  scroll-behavior: smooth; }
  body.fade-in {
    opacity: 1;
    transition: opacity  2s ease; }

@media all and (min-width: 769px) {
  .row {
    display: flex;
    flex-wrap: wrap; }
    .row .col {
      flex: 0 0 100%;
      width: 100%; }
    .row .col-6 {
      flex: 0 0 50%;
      width: 50%; } }

@media all and (max-width: 768px) {
  .row:not(.nopd), .editorBox {
    padding: 0 22px; }
  .footerH {
    width: 100%;
    height: 100px; }
  .formH {
    width: 100%;
    height: 120px; }
  #Wapper {
    position: relative;
    padding-top: 55px; } }

@media all and (min-width: 769px) {
  .row:not(.nopd), .editorBox {
    padding: 0 60px; }
  .row.vdobx .col-6 {
    padding-bottom: 8px; }
  .row.vdobx .col-6:nth-child(odd) {
    padding-right: 4px; }
  .row.vdobx .col-6:nth-child(even) {
    padding-left: 4px; }
  .footerH {
    width: 100%;
    height: 230px; }
  .formH {
    width: 100%;
    height: 120px; }
  #Wapper {
    position: relative;
    padding-top: 67px; } }

h1.mtitle .ftw, h6.mtitle .ftw {
  color: #221815;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    h1.mtitle .ftw, h6.mtitle .ftw {
      font-size: 1.5625rem; } }
  @media all and (min-width: 576px) {
    h1.mtitle .ftw, h6.mtitle .ftw {
      font-size: 2.125rem; } }

h1.mtitle .fen, h6.mtitle .fen {
  color: #221815;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    h1.mtitle .fen, h6.mtitle .fen {
      font-size: 1.5625rem; } }
  @media all and (min-width: 576px) {
    h1.mtitle .fen, h6.mtitle .fen {
      font-size: 2.25rem; } }

h1.mtitle.fwh .ftw,
h1.mtitle.fwh .fen, h6.mtitle.fwh .ftw,
h6.mtitle.fwh .fen {
  color: white; }

@media all and (max-width: 768px) {
  h1.mtitle, h6.mtitle {
    position: absolute;
    top: 1.3em;
    left: 0; } }

@media all and (min-width: 769px) {
  h1.mtitle, h6.mtitle {
    position: absolute;
    top: 26px;
    left: 0; } }

.form-txt1 h1 {
  color: #221815;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01em;
  font-size: 1.125rem;
  margin-bottom: 6px;
  font-family: "Montserrat-Bold", sans-serif; }

.form-txt1 .stext {
  color: #221815;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.01em;
  font-size: 1rem; }

@media all and (max-width: 768px) {
  .form-txt1 {
    margin: 12px 0; } }

@media all and (min-width: 769px) {
  .form-txt1 {
    margin: 24px 0; } }

.ftitle {
  text-align: center;
  color: #221815;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.01em;
  font-size: 1.125rem; }
  @media all and (max-width: 768px) {
    .ftitle {
      margin: 30px 0 24px; } }
  @media all and (min-width: 769px) {
    .ftitle {
      margin: 48px 0 24px; } }

.row-col-8 {
  max-width: 1305px;
  width: 100%;
  margin: 0 auto; }
  @media all and (max-width: 768px) {
    .row-col-8 {
      padding: 0 22px; } }
  @media all and (min-width: 769px) {
    .row-col-8 {
      padding: 0 60px; } }

.row-col-4 {
  max-width: 690px;
  width: 100%;
  margin: 0 auto; }
  @media all and (max-width: 768px) {
    .row-col-4 {
      padding: 0 22px; } }
  @media all and (min-width: 769px) {
    .row-col-4 {
      padding: 0 60px; } }

.temp-bg {
  position: relative; }
  .temp-bg .bgcolor {
    background-color: rgba(152, 112, 51, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 54px 45px;
    opacity: 0;
    transition: opacity 0.6s; }
    @media all and (max-width: 768px) {
      .temp-bg .bgcolor {
        padding: 18px 22px; } }
    @media all and (min-width: 769px) {
      .temp-bg .bgcolor {
        padding: 54px 60px; } }
  .temp-bg .ftw {
    color: white;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01em; }
    @media all and (max-width: 575px) {
      .temp-bg .ftw {
        font-size: 1.5625rem; } }
    @media all and (min-width: 576px) {
      .temp-bg .ftw {
        font-size: 2.125rem; } }
  .temp-bg .fen {
    color: white;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01em; }
    @media all and (max-width: 575px) {
      .temp-bg .fen {
        font-size: 1.5625rem; } }
    @media all and (min-width: 576px) {
      .temp-bg .fen {
        font-size: 2.25rem; } }
  .temp-bg a {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .temp-bg.hover .bgcolor {
    opacity: 1; }
  @media all and (min-width: 769px) {
    .temp-bg.hover a {
      display: block; } }

@media all and (max-width: 768px) {
  .slick-img .temp-bg {
    width: 84.9vw; } }

@media all and (min-width: 769px) {
  .slick-img .temp-bg {
    width: 40.83vw; } }

.search {
  margin-top: -0.5em; }
  .search a {
    color: #8c8c8c;
    font-weight: 400;
    line-height: 2.5;
    letter-spacing: 0.01em;
    height: 2.5em;
    line-height: 2.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; }
    @media all and (max-width: 575px) {
      .search a {
        font-size: 1rem; } }
    @media all and (min-width: 576px) {
      .search a {
        font-size: 0.875rem; } }
  .search i {
    color: #221815; }
  .search a:hover {
    color: #987033; }

.no-search {
  text-align: center;
  color: #221815;
  font-weight: 400;
  line-height: 1.57;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .no-search {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .no-search {
      font-size: 0.875rem; } }

.history {
  color: #221815;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .history {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .history {
      font-size: 0.875rem; } }
  .history li:nth-child(odd) {
    background-color: #d7d7d7; }
  @media all and (max-width: 768px) {
    .history li {
      padding: 8px 0; } }
  @media all and (min-width: 769px) {
    .history li {
      padding: 15px 0; } }

.ambs .mCSB_scrollTools .mCSB_draggerContainer {
  opacity: 0; }

.ambs .mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 0; }

.ambs .scrol-img {
  display: flex; }

@media all and (max-width: 768px) {
  .ambs .temp-bg {
    width: 84.9vw;
    flex: 0 0 84.9vw; } }

@media all and (min-width: 769px) {
  .ambs .temp-bg {
    width: 45.57vw;
    flex: 0 0 45.57vw; } }

.ambs .fen {
  display: block; }

.v_filter {
  width: 100%;
  color: #8c8c8c;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  display: flex; }
  @media all and (max-width: 575px) {
    .v_filter {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .v_filter {
      font-size: 0.875rem; } }
  .v_filter .txt {
    flex: 0 0 5.5em; }
  .v_filter a {
    color: #8c8c8c;
    margin-right: 1em;
    white-space: nowrap; }
  .v_filter a:before {
    content: "●";
    margin-right: 0.5em;
    color: #d7d7d7; }
  .v_filter a.active:before {
    color: #987033; }
  @media all and (max-width: 768px) {
    .v_filter {
      padding: 20px 22px;
      overflow-x: scroll; }
      .v_filter .cate {
        display: flex; } }
  @media all and (min-width: 769px) {
    .v_filter {
      padding: 28px 60px; } }

.srline {
  width: 100%;
  height: 1px; }
  @media all and (max-width: 768px) {
    .srline {
      transform: translateY(-55px); } }
  @media all and (min-width: 769px) {
    .srline {
      transform: translateY(-67px); } }

.formtable input, .formtable select, .formtable textarea, .formtable .date_txt {
  background: white;
  outline: none;
  appearance: none;
  box-shadow: none;
  border: none;
  width: 100%;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif;
  color: #221815;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  vertical-align: middle; }
  .formtable input:focus, .formtable select:focus, .formtable textarea:focus, .formtable .date_txt:focus {
    outline: none; }
  @media all and (max-width: 575px) {
    .formtable input, .formtable select, .formtable textarea, .formtable .date_txt {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .formtable input, .formtable select, .formtable textarea, .formtable .date_txt {
      font-size: 0.875rem; } }
  @media all and (max-width: 768px) {
    .formtable input, .formtable select, .formtable textarea, .formtable .date_txt {
      padding: 14px 24px 12px; } }
  @media all and (min-width: 769px) {
    .formtable input, .formtable select, .formtable textarea, .formtable .date_txt {
      padding: 6px 24px 3px; } }

@media all and (max-width: 768px) {
  .formtable .date_txt i {
    display: none; } }

@media all and (max-width: 768px) {
  .formtable input[type="date"] {
    height: 50px; } }

@media all and (min-width: 769px) {
  .formtable input[type="date"] {
    height: 30px; } }

.formtable #captcha {
  width: 50%;
  margin-right: 12px; }

.formtable a.again {
  position: relative;
  color: #8c8c8c;
  margin-left: -3px; }

.formtable a.again:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -25px;
  width: 25px;
  height: 15px;
  background: no-repeat url(../svg/refrsh.svg) center/15px 15px; }

.formtable input[disabled] {
  opacity: 1;
  background-color: #d7d7d7;
  color: black; }

.formtable textarea {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.formtable ::-webkit-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif; }

.formtable :-moz-placeholder {
  color: #8c8c8c;
  font-weight: 400;
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif; }

.formtable ::-moz-placeholder {
  color: #8c8c8c;
  font-weight: 400;
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif; }

.formtable :-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
  font-family: "Montserrat-Regular", "Noto Sans SC", "Microsoft Yahei", "Microsoft JhengHei", sans-serif; }

.formtable .date_txt {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  color: #8c8c8c;
  height: 100%; }
  .formtable .date_txt.hide {
    display: none; }
  @media all and (max-width: 768px) {
    .formtable .date_txt {
      width: calc(100% - 44px);
      padding-right: 0; } }
  @media all and (min-width: 769px) {
    .formtable .date_txt {
      width: 25em; } }

.formtable .with-errors {
  color: #de1d1a;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  letter-spacing: 0.025em;
  display: block;
  max-width: 100%;
  min-height: 1.5em;
  margin: 8px 0 6px;
  padding-left: 24px; }
  @media all and (max-width: 575px) {
    .formtable .with-errors {
      font-size: 0.875rem; } }
  @media all and (min-width: 576px) {
    .formtable .with-errors {
      font-size: 0.75rem; } }

.formtable .btn {
  display: block;
  text-align: center;
  background-color: #987033;
  color: white;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  transition: 0s; }
  @media all and (max-width: 575px) {
    .formtable .btn {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .formtable .btn {
      font-size: 0.875rem; } }
  @media all and (max-width: 768px) {
    .formtable .btn {
      padding: 14px 24px 12px; } }
  @media all and (min-width: 769px) {
    .formtable .btn {
      padding: 6px 24px 3px; }
      .formtable .btn.w50 {
        width: 50%; } }

.formtable .a_group a {
  color: #8c8c8c;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .formtable .a_group a {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .formtable .a_group a {
      font-size: 0.875rem; } }

@media all and (max-width: 768px) {
  .formtable .a_group {
    margin-top: 30px; }
    .formtable .a_group a {
      display: block;
      margin-bottom: 6px; }
    .formtable .a_group.mtb-histry {
      margin-top: -20px; } }

@media all and (min-width: 769px) {
  .formtable .a_group {
    margin-top: 12px; }
    .formtable .a_group.mtb-histry {
      margin-top: -18px;
      margin-bottom: 8px; } }

.formtable .check_radi {
  width: auto;
  position: relative;
  cursor: pointer;
  user-select: none; }
  .formtable .check_radi input[type="checkbox"], .formtable .check_radi input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin: 0; }
  .formtable .check_radi .checkmark {
    position: absolute;
    top: 0.3em;
    left: 0;
    height: 13px;
    width: 13px;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 1px solid #8c8c8c;
    background-color: #f0f0f0; }
  .formtable .check_radi input:checked ~ .checkmark {
    background-color: #987033; }

.formtable .privacy {
  color: #221815;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .formtable .privacy {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .formtable .privacy {
      font-size: 0.875rem; } }
  .formtable .privacy a {
    color: #8c8c8c;
    position: relative;
    z-index: 6; }
  .formtable .privacy .check_radi {
    width: 12em;
    padding-left: 20px; }

.indx-banner .bgcover {
  padding-bottom: 70%; }

.indx-banner .btn-slick-prev, .indx-banner .btn-slick-next {
  transform: translateY(-50%);
  cursor: pointer; }

.indx-banner .btn-slick-prev {
  position: absolute;
  top: 50%;
  left: 0; }

.indx-banner .btn-slick-next {
  position: absolute;
  top: 50%;
  right: 0; }

@media all and (max-width: 768px) {
  .indx-banner .btn-slick-prev, .indx-banner .btn-slick-next {
    width: 24px; } }

.indx-about {
  color: #987033;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .indx-about {
      font-size: 1.5625rem; } }
  @media all and (min-width: 576px) {
    .indx-about {
      font-size: 2.25rem; } }
  .indx-about a {
    position: relative;
    color: #987033; }
  .indx-about a:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #987033;
    position: absolute;
    bottom: 0;
    left: 0px; }
  .indx-about .col {
    max-width: 20em; }
  @media all and (max-width: 768px) {
    .indx-about {
      padding: 20px 22px; } }
  @media all and (min-width: 769px) {
    .indx-about {
      padding: 45px 60px; } }

.indx-pdf {
  background-color: #987033;
  color: white;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: 0.01em; }
  @media all and (max-width: 575px) {
    .indx-pdf .mtitle {
      font-size: 1.5625rem; } }
  @media all and (min-width: 576px) {
    .indx-pdf .mtitle {
      font-size: 2.25rem; } }
  .indx-pdf a {
    color: white; }
    @media all and (max-width: 575px) {
      .indx-pdf a {
        font-size: 0.875rem; } }
    @media all and (min-width: 576px) {
      .indx-pdf a {
        font-size: 1.125rem; } }
  @media all and (max-width: 768px) {
    .indx-pdf.row:not(.nopd) {
      padding-top: 16px;
      padding-bottom: 56px; }
    .indx-pdf .mtitle {
      margin-bottom: 16px; }
    .indx-pdf a.txt {
      position: absolute;
      bottom: 24px;
      left: 22px; } }
  @media all and (min-width: 769px) {
    .indx-pdf .col-6 {
      padding: 45px 0; }
    .indx-pdf .mtitle {
      margin-bottom: 32px; } }

.news .items:nth-child(even) {
  background-color: #d7d7d7; }

.news .stext {
  color: #221815;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.01em; }
  .news .stext h5 {
    font-weight: bold; }
  @media all and (max-width: 575px) {
    .news .stext {
      font-size: 1rem; } }
  @media all and (min-width: 576px) {
    .news .stext {
      font-size: 1rem; } }
  @media all and (max-width: 768px) {
    .news .stext {
      line-height: 1.56;
      padding: 14px 0 20px; } }
  @media all and (min-width: 769px) {
    .news .stext {
      padding: 24px 0 30px; } }

@media all and (max-width: 768px) {
  .news .mimg {
    padding-top: 20px; } }

@media all and (min-width: 769px) {
  .news .mimg {
    padding-top: 30px; } }

.news .videobx {
  background-color: black; }
  @media all and (max-width: 768px) {
    .news .videobx {
      padding: 22px 0; } }
  @media all and (min-width: 769px) {
    .news .videobx {
      padding: 30px 0; } }

.news footer {
  background-color: rgba(0, 0, 0, 0); }
