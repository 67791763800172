/*----------------------通用設定------------------------*/
//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
  
}
::selection{
  color:#fff;
  background:$color1;
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}

/*----------------------共用樣式------------------------*/
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
/*----------------------編輯器------------------------*/
.editor_inner{  
  @include font-color($color2,400,1.75);
  font-size: pxToEm(16);
  margin: 0 auto;
  ul,ol {
    padding-left: 10px;
  }
  ol{
    list-style: decimal;
  }
  ul{
    list-style: disc;
  }
  img {
      max-width: 100%;
      height: auto !important;
  }
  b,strong{
    font-weight: bold;
    font-style: inherit;
  }
  em{
  font-style: italic; 
  }
  a{
    text-decoration: underline;
    color:#0782C1;
  }
  iframe {
      max-width: 100%;
  }
  h1,h2,h3,h4,h5,h6 {
      margin: inherit;
  }
  blockquote{
    font-style: italic;
    padding: 2px 20px 0 8px;
    border-left:5px solid #ccc;
    margin-left: 40px;
  }
  @include mediaquery(null,$sm){
    line-height: 1.5625;
  }
  @include mediaquery(null,$md){
    &.show-pc{
      display: none;
    }
  }
  @include mediaquery($md,null){
    &.show-mo{
      display: none;
    }
    + .back{
      margin-top: 100px;
    }
  }
}
/*-------------------背景圖片 桌手機分開-----------------*/
@include mediaquery(null,$sm){
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include mediaquery($sm,null){
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}
#topBtn{
  width: 80px;
  @include position(absolute,$bottom:0,$left:50%);
  transform: translateX(-50%);  
  opacity: 0;
  visibility: hidden;
  &.open{
    opacity: 1;
    visibility: visible;
  }
  @include mediaquery(null,$md){
    width: 60px;
  }
  @include mediaquery($md,null){
    width: 80px;
  }
}
/*---------------------video----------------------*/
.embed-responsive{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item,iframe{
    @include position(absolute,$top:0,$left:0,$right:0,$bottom:0);
    width:100% !important;
    height: 100% !important;
  }
  &.embed-responsive-16by9:before{
    content:"";
    display: block;
    padding-top: 69.58%;
  }
}