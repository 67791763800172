.formtable{   
    input,select,textarea,.date_txt{
        background:white;
        outline: none;
        appearance: none;
        box-shadow:none;
        border: none;
        width:100%;
        @include border();
        &:focus { outline: none; }
        font-family: $mfont;
        @include font-color($color2,400,1.5);
        @include font-size(pxToEm(16),pxToEm(14));
        vertical-align: middle; 
        @include mediaquery(null,$md){
            padding:14px 24px 12px;
        }
        @include mediaquery($md,null){ 
            padding:6px 24px 3px;
        }
    }
    .date_txt i{
        @include mediaquery(null,$md){
            display: none;
        }
    }
    input[type="date"]{
        @include mediaquery(null,$md){
            height: 50px;
        }
        @include mediaquery($md,null){ 
            height: 30px;
        }

    }
    //驗證碼
    #captcha{
        width: 50%;
        margin-right: 12px;
    }
    a.again{
        position: relative;
        color: #8c8c8c;
        margin-left: -3px;
    }
    a.again:before{
        content:"";
        @include position(absolute,$top:2px,$left:-25px);
        width:25px;
        height: 15px;
        background:no-repeat url(../svg/refrsh.svg) center / 15px 15px;
    }
    input[disabled]{
        opacity: 1;
        background-color: #d7d7d7;
        color: black;
    }
    textarea{
        @include border(12px);
    }
    @include placeholder {
        color:#8c8c8c;
        font-weight: 400;
        font-family: $mfont;
    }    
    // label{
    //     position: relative;
    //     display: block;
    //     font-size: pxToEm(16);
    //     @include font-color($color1,600,1.5);
    //     padding: 0.2em 0;
    // }
    // label.requ:after{
    //     content:"*";
    //     color:#fb0009;
    //     vertical-align: 4px;
    // }
    .date_txt{
        // @include border();
        // @include font-color($color3,400,1.5);
        // @include font-size(pxToEm(16),pxToEm(14));
        @include position(absolute,$top:0,$left:0);
        background-color: white;
        color: $color3;
        height: 100%;
        &.hide{
            display: none;
        }
        @include mediaquery(null,$md){
            width:calc(100% - 44px);
            padding-right: 0;
        }
        @include mediaquery($md,null){            
            width: 25em;
        }
    }
    .with-errors{
        @include font-size(pxToEm(14),pxToEm(12));
        @include font-color(#de1d1a,400,1.5);
        letter-spacing: 0.025em;
        display:block;
        max-width: 100%;
        min-height:1.5em;
        margin: 8px 0 6px;
        padding-left: 24px;
    }
    .btn{
        display: block;
        text-align: center;
        background-color: $color1;
        @include font-color(white,400,1.5);
        @include font-size(pxToEm(16),pxToEm(14));
        @include border();
        transition: 0s;
        @include mediaquery(null,$md){
            padding:14px 24px 12px;
        }
        @include mediaquery($md,null){
            padding:6px 24px 3px;
            &.w50{
                width:50%;
            }
        }
    }
    .a_group{
        a{
            @include font-color($color3,400,2);
            @include font-size(pxToEm(16),pxToEm(14));
        }
        @include mediaquery(null,$md){
            margin-top: 30px;
            a{
                display: block;
                margin-bottom: 6px;
            }
            &.mtb-histry{
                margin-top: -20px;
            }
        }
        @include mediaquery($md,null){
            margin-top: 12px;
            &.mtb-histry{
                margin-top: -18px;
                margin-bottom: 8px;
            }
        }
    }
    // .srhbtn{
    //     cursor: pointer;
    //     background: no-repeat url(../svg/search.svg) center;
    //     @include position(absolute,$top:50%,$right:0px);
    //     transform: translateY(-50%);
    //     width:40px;
    //     height: 40px;
    //     @include mediaquery(null,$sm){
    //         background-size: 22px;
    //     }
    //     @include mediaquery($sm,null){
    //         background-size: 26px;
    //     }
    // }
    .check_radi {
        width: auto;
        position: relative;
        cursor: pointer;
        user-select: none;
        input[type="checkbox"],input[type="radio"]{
            @include position(absolute,$top:0,$left:0);
            opacity: 0;
            cursor: pointer;
            z-index: 5;
            width: 100%;
            height: 100%;
            margin:0;
        }
        // .boxmark {
        //     @include position(absolute,$top:50%,$left:50%);
        //     height: 20px;
        //     width: 20px;
        //     border:1px solid #777;
        //     @include border();
        //     @include mediaquery(null,$sm){
        //         transform: translate(-50%,-50%) scale(0.7);
        //         transform-origin: left;
        //     }
        //     @include  mediaquery($sm,null){
        //         transform: translate(-50%,-50%);
        //     }
        // }
        // .boxmark:after {
        //     content: "";
        //     @include position(absolute,$top:50%,$left:50%);
        //     transform: translate(-50%,-50%);
        //     display: none;
        //     width: 10px;
        //     height: 10px;
        //     background: $color1;
        //     @include border();
        // }
        // input:checked~.boxmark:after {
        //     display: block;
        // }
        .checkmark {
            @include position(absolute,$top:0.3em,$left:0);
            height: 13px;
            width: 13px;
            @include border();
            border: 1px solid #8c8c8c;
            background-color: #f0f0f0;
            // @include mediaquery(null,$sm){
            //     top:calc(0.3em + 1px);
            // }
        }
        input:checked~.checkmark{
            background-color: $color1;
        }
        // .checkmark:after {
        //     content: "";
        //     @include position(absolute,$top:1px,$left:3px);
        //     display: none;
        //     width: 20px;
        //     height: 20px;
        //     background: $color1;
        // }
        // input:checked~.checkmark:after {
        //     display: block;
        // }
    } 
    //privacy
    .privacy{
        @include font-color($color2,400,1.5);
        @include font-size(pxToEm(16),pxToEm(14));
        a{
            color:$color3;
            position: relative;
            z-index: 6;
        }
        .check_radi{
            width:12em;
            padding-left: 20px;
        }
    }
}